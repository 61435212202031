import { useState } from 'react';
import { Typography } from '..';
import { ArrowDownIcon } from '../../assets/icons';
import {
  ItemsBoxPerPage,
  ItemsPerPage,
  SizeOptionsBox,
  Content,
  ItemSize,
} from './dropdown.styles';

import { IProps } from './dropdown.types';

export default function Dropdown({
  itemsPerPage,
  setItemsPerPage,
  totalItems,
  setPage,
}: IProps) {
  const [toggle, setToggle] = useState(false);
  const SIZE_OPTIONS = [5, 10, 15, 20, 25];

  const handleClick = (size: number) => {
    if (!totalItems) return;
    if (size > totalItems) {
      setPage(1);
    }
    setItemsPerPage(size);
    setToggle((value) => !value);
  };

  return (
    <Content>
      <ItemsBoxPerPage>
        <Typography fontSize="12" color="#61596C">
          Linhas por página:
        </Typography>
        <ItemsPerPage onClick={() => setToggle((value) => !value)}>
          <Typography fontSize="12" color="#61596C">
            {itemsPerPage}
          </Typography>
          <ArrowDownIcon fill="#C8C1D0" />
        </ItemsPerPage>
      </ItemsBoxPerPage>
      {toggle && (
        <SizeOptionsBox>
          {SIZE_OPTIONS.map((size) => (
            <ItemSize key={size} onClick={() => handleClick(size)}>
              {size}
            </ItemSize>
          ))}
        </SizeOptionsBox>
      )}
    </Content>
  );
}
