import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Desktop,
  Establishment,
  LogisticsIcon,
  TransactionIcon,
  UserIcon,
  PaymentsIcon,
} from '../../assets/icons';
import NestedPaths from './components/NestedPaths/nested-paths.component';
import { Menu, NavBar, NavItem, NavList } from './sidebar.styles';
import { NavListType } from './sidebar.types';

export function Sidebar() {
  const { pathname } = window.location;

  const navList: NavListType[] = useMemo(
    () => [
      // { name: 'Financeiro', icon: <FinancialIcon />, path: '/financeiro' },
      // { name: 'Relatório', icon: <ReportIcon />, path: '/relatorio' },
      {
        name: 'Estabelecimentos',
        icon: <Establishment />,
        path: '/estabelecimento',
        nestedPaths: [
          {
            name: 'Cadastro de EC',
            path: '/estabelecimento/cadastro',
          },
          {
            name: 'Lista de ECs - Wallet',
            path: '/estabelecimento/listagem-wallet',
          },
          {
            name: 'Lista de ECs - Marketplace',
            path: '/estabelecimento/listagem-marketplace',
          },
          {
            name: 'Cadastro de marca',
            path: '/estabelecimento/cadastro/marca',
          },
          {
            name: 'Listagem de marca',
            path: '/estabelecimento/marca/listagem',
          },
        ],
      },
      {
        name: 'Logistíca',
        icon: <LogisticsIcon />,
        path: '/logistics',
        nestedPaths: [
          {
            name: 'Cadastro de taxa de entrega',
            path: '/logistics/delivery-register-fee',
          },
          {
            name: 'Monitoramento de entregas',
            path: '/logistics/delivery-monitoring',
          },
        ],
      },
      {
        name: 'Pedidos',
        icon: <TransactionIcon />,
        path: '/transactions',
      },
      {
        name: 'Consumidores',
        icon: <UserIcon />,
        path: '/consumidores',
      },
      {
        name: 'Operações',
        icon: <Desktop />,
        path: '/operacoes',
        nestedPaths: [
          {
            name: 'Cadastro de Usuário - Portal do EC',
            path: '/operacoes/cadastro-usuario',
          },
          {
            name: 'Consulta de Usuários - Portal do EC',
            path: '/operacoes/usuarios',
          },
        ],
      },
      {
        name: 'Pagamentos',
        icon: <PaymentsIcon />,
        path: '/pagamentos/config-master',
        nestedPaths: [
          {
            name: 'Configurações Braspag',
            path: '/pagamentos/config-master',
          },
        ],
      },
    ],
    [],
  );

  return (
    <Menu>
      <NavBar>
        <NavList>
          {navList.map((item) =>
            item?.nestedPaths?.length ? (
              <NestedPaths
                paths={item}
                key={item.path}
                isActive={item.path.includes(pathname.split('/')[1])}
              />
            ) : (
              <NavItem isActive={pathname === item.path} key={item.path}>
                {item.icon}
                <Link to={item.path}>{item.name}</Link>
              </NavItem>
            ),
          )}
        </NavList>
      </NavBar>
    </Menu>
  );
}
