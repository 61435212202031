import * as React from 'react';

function ArrowLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.0800004 7.38006C0.127595 7.50281 0.198961 7.61495 0.290001 7.71006L5.29 12.7101C5.38324 12.8033 5.49393 12.8773 5.61575 12.9277C5.73757 12.9782 5.86814 13.0041 6 13.0041C6.2663 13.0041 6.5217 12.8984 6.71 12.7101C6.80324 12.6168 6.8772 12.5061 6.92766 12.3843C6.97812 12.2625 7.00409 12.1319 7.00409 12.0001C7.00409 11.7338 6.8983 11.4784 6.71 11.2901L3.41 8.00006L11 8.00006C11.2652 8.00006 11.5196 7.8947 11.7071 7.70717C11.8946 7.51963 12 7.26528 12 7.00006C12 6.73484 11.8946 6.48049 11.7071 6.29295C11.5196 6.10542 11.2652 6.00006 11 6.00006L3.41 6.00006L6.71 2.71006C6.80373 2.61709 6.87812 2.50649 6.92889 2.38464C6.97966 2.26278 7.0058 2.13207 7.0058 2.00006C7.0058 1.86805 6.97966 1.73734 6.92889 1.61548C6.87812 1.49362 6.80373 1.38302 6.71 1.29006C6.61704 1.19633 6.50644 1.12194 6.38458 1.07117C6.26272 1.0204 6.13201 0.99426 6 0.99426C5.86799 0.99426 5.73728 1.0204 5.61542 1.07117C5.49356 1.12194 5.38296 1.19633 5.29 1.29006L0.290002 6.29006C0.198961 6.38516 0.127595 6.49731 0.0800005 6.62006C-0.0200176 6.86352 -0.0200176 7.1366 0.0800004 7.38006Z"
        fill="#4B415C"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
