import styled from 'styled-components';

export const HeaderS = styled.header`
  position: fixed;
  width: 100%;
  background: #35007d;
  min-height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  z-index: 100;
`;

export const Branding = styled.img``;

export const UserBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`;

export const Avatar = styled.div`
  width: 32px;
  height: 32px;
  background: #6a2cb1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  color: #fff;
`;

export const UserName = styled.span`
  color: #fff;
  margin-left: 8px;
  margin-right: 12.5px;
`;

export const ArrowBox = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const DropwDownContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const Box = styled.div`
  padding: 16px 0px 0px 16px;
`;

export const LogoutButton = styled.button`
  background: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const DropDown = styled.div`
  margin-top: 72px;
  background: #fff;
  height: 197px;
  padding-right: 8px;
  position: absolute;
  margin-right: 16px;
  border-radius: 4px;
  z-index: 10000;
`;

export const Arrow = styled.div`
  position: absolute;
  right: 0;
  margin-right: 16px;
  top: -6px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  margin-top: 1px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #e2e1e3;
  margin: 16px 0 0 0;
`;
