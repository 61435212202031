/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { signOut, getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ENDPOINTS } from '../endpoints';
import useHTTPHeaders from '../HTTP/headers';
import {
  clearAuthFromLocalStorage,
  clearAuthFromSessionStorage,
  getAuthFromLocalStorage,
  getAuthFromSessionStorage,
} from '../util/storage';
import { getEmail, getFullName, getInitialFromFullName } from '../util/user';
import api from '../config/api';

const UserContext = createContext<any>({});

export interface IUserInfo {
  id: number | string;
  firstName: string;
  lastName: string;
  email: string;
}

export function UserProvider({ children }: any) {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);

  const fullName = getFullName(userInfo?.firstName, userInfo?.lastName);
  const initials = getInitialFromFullName(
    userInfo?.firstName,
    userInfo?.lastName,
  );
  const email = getEmail(userInfo?.email);
  const auth = getAuth();
  const [user]: any = useAuthState(auth);

  const logout = () => {
    user?.accessToken && signOut(auth);
    clearAuthFromLocalStorage();
    clearAuthFromSessionStorage();
    history.push('/');
  };

  const getUserInfo = async () => {
    try {
      setIsLoading(true);
      const token =
        getAuthFromLocalStorage() || getAuthFromSessionStorage() || '';
      const { data } = await api.get(ENDPOINTS.user.list, {
        headers: { Authorization: token },
      });
      setUserInfo(data);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        fullName,
        initials,
        email,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);

  return context;
}
