export const cepMask = (cep: string) => {
  if (!cep) return '';

  const maskedCEP = cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2');

  return maskedCEP;
};

export const removeCepMask = (cep: string) => {
  if (!cep) return '';
  return cep?.replace('-', '');
};
