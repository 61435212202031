import styled from 'styled-components';
import { IStyledTypography } from './typography.types';

export const StyledTypography = styled.span<IStyledTypography>`
  display: block;
  font-family: 'Open Sans';
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};
  font-weight: ${(props) => props.fontWeight || ''};
  line-height: ${(props) => props.lineHeight || ''};
  color: ${(props) => props.color || ''};
`;
