import { getAuth } from 'firebase/auth';
import { Redirect, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useEffect } from 'react';
import {
  getAuthFromSessionStorage,
  getAuthFromLocalStorage,
  setAuthInLocalStorage,
} from '../util/storage';

function PrivateRoute({
  component: Component,
  ...rest
}: {
  component: any;
  exact: boolean;
  path: string;
}) {
  const auth = getAuth();
  const [user]: any = useAuthState(auth);

  const isAuthenticated = () => {
    const token =
      getAuthFromSessionStorage() ||
      getAuthFromLocalStorage() ||
      user?.accessToken ||
      null;

    const isAuth = token !== null;
    return isAuth;
  };

  useEffect(() => {
    user?.accessToken && setAuthInLocalStorage(user?.accessToken);
  }, [user?.accessToken]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  );
}

export default PrivateRoute;
