import axios from 'axios';

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth') || sessionStorage.getItem('auth');
  if (config.headers) {
    config.headers.HTTP_AUTHORIZATION = `Bearer ${token}` || '';
  }
  return config;
});

export default api;
