import * as React from 'react';

function ArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.33346 1.1133C7.20855 0.989128 7.03959 0.919434 6.86346 0.919434C6.68734 0.919434 6.51837 0.989128 6.39346 1.1133L4.00013 3.4733L1.64013 1.1133C1.51522 0.989128 1.34625 0.919434 1.17013 0.919434C0.994005 0.919434 0.825037 0.989128 0.700129 1.1133C0.637643 1.17527 0.588047 1.249 0.554201 1.33024C0.520355 1.41148 0.50293 1.49862 0.50293 1.58663C0.50293 1.67464 0.520355 1.76177 0.554201 1.84301C0.588047 1.92425 0.637643 1.99799 0.700129 2.05996L3.52679 4.88663C3.58877 4.94911 3.6625 4.99871 3.74374 5.03256C3.82498 5.0664 3.91212 5.08383 4.00013 5.08383C4.08814 5.08383 4.17527 5.0664 4.25651 5.03256C4.33775 4.99871 4.41149 4.94911 4.47346 4.88663L7.33346 2.05996C7.39595 1.99799 7.44554 1.92425 7.47939 1.84301C7.51323 1.76177 7.53066 1.67464 7.53066 1.58663C7.53066 1.49862 7.51323 1.41148 7.47939 1.33024C7.44554 1.249 7.39595 1.17527 7.33346 1.1133Z"
        fill={props.fill || 'white'}
      />
    </svg>
  );
}

export default ArrowDownIcon;
