import * as React from 'react';

function ArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.70023 4.88988C0.825139 5.01405 0.994106 5.08374 1.17023 5.08374C1.34635 5.08374 1.51532 5.01405 1.64023 4.88988L4.03356 2.52988L6.39356 4.88988C6.51847 5.01405 6.68744 5.08374 6.86356 5.08374C7.03969 5.08374 7.20865 5.01405 7.33356 4.88988C7.39605 4.8279 7.44564 4.75417 7.47949 4.67293C7.51334 4.59169 7.53076 4.50455 7.53076 4.41655C7.53076 4.32854 7.51334 4.2414 7.47949 4.16016C7.44564 4.07892 7.39605 4.00519 7.33356 3.94321L4.5069 1.11654C4.44492 1.05406 4.37119 1.00446 4.28995 0.970617C4.20871 0.936772 4.12157 0.919346 4.03356 0.919346C3.94556 0.919346 3.85842 0.936772 3.77718 0.970617C3.69594 1.00446 3.62221 1.05406 3.56023 1.11654L0.70023 3.94321C0.637744 4.00519 0.588149 4.07892 0.554303 4.16016C0.520457 4.2414 0.503031 4.32854 0.503031 4.41655C0.503031 4.50455 0.520457 4.59169 0.554303 4.67293C0.588149 4.75417 0.637744 4.8279 0.70023 4.88988Z"
        fill={props.fill ?? 'white'}
      />
    </svg>
  );
}

export default ArrowDownIcon;
