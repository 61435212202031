import { Header, Sidebar } from '@/layout';
import React from 'react';
import styled from 'styled-components';
import PrivateRoute from './private.route';

const Finantial = React.lazy(
  () => import('../pages/Finantial/finantial.component'),
);
const Reports = React.lazy(() => import('../pages/Reports/reports.component'));

const RejectLines = React.lazy(
  () => import('../pages/RejectedLines/rejected-lines.component'),
);

const EstablishmentRegister = React.lazy(
  () =>
    import(
      '../pages/Establishment/EstablishmentRegister/establishment-register.page'
    ),
);

const EstablishmentListWallet = React.lazy(
  () =>
    import('../pages/Establishment/EstablishmentList/establishment-list.page'),
);

const EstablishmentListMktp = React.lazy(
  () =>
    import(
      '../pages/Establishment/EstablishmentListMktp/establishment-list-mktp.page'
    ),
);

const EstablishmentInfo = React.lazy(
  () =>
    import('../pages/Establishment/EstablishmentInfo/establishment-info.page'),
);

const EstablishmentInfoMktp = React.lazy(
  () =>
    import(
      '../pages/Establishment/EstablishmentInfoMktp/establishment-info-mktp.page'
    ),
);

const PaymentMethods = React.lazy(
  () => import('../pages/Establishment/PaymentMethods/payment-methods.page'),
);

const BrandRegister = React.lazy(
  () => import('../pages/Establishment/BrandRegister/brand-register.page'),
);

const BrandList = React.lazy(
  () => import('../pages/Establishment/BrandList/brand-list.page'),
);

const LogisticsPage = React.lazy(
  () =>
    import('../pages/Logistics/DeliveryFeeRegister/delivery-fee-register.page'),
);

const DeliveryMonitoring = React.lazy(
  () =>
    import('../pages/Logistics/DeliveryMonitoring/delivery-monitoring.page'),
);
const DeliveryBoard = React.lazy(
  () => import('../pages/Logistics/DeliveryBoard/delivery-board.page'),
);

const TransactionsPage = React.lazy(
  () => import('../pages/Transactions/transaction.page'),
);

const ConsumersList = React.lazy(
  () => import('../pages/Consumers/consumers-list.page'),
);

const ConsumersDetails = React.lazy(
  () => import('../pages/Consumers/ConsumersDetails/consumers-details.page'),
);

const UserRegistration = React.lazy(
  () => import('../pages/Operations/UserRegistration/user-registration.page'),
);

const PaymentsMasterList = React.lazy(
  () => import('../pages/Payments/master/master-list.page'),
);

const PaymentsMasterEdit = React.lazy(
  () => import('../pages/Payments/master/master-edit.page'),
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  background: #fbf9fc;
  padding: 24px;
  position: relative;
`;

const PortalUsers = React.lazy(
  () => import('../pages/Operations/PortalUsers/portal-users.page'),
);

function PrivateRoutes() {
  return (
    <Container>
      <Header />
      <div style={{ display: 'flex', paddingTop: '72px' }}>
        <Sidebar />
        <Content>
          <PrivateRoute exact path="/financeiro" component={Finantial} />
          <PrivateRoute
            exact
            path="/financeiro/linhas-com-rejeicao"
            component={RejectLines}
          />
          <PrivateRoute exact path="/relatorio" component={Reports} />
          <PrivateRoute
            exact
            path="/estabelecimento/cadastro"
            component={EstablishmentRegister}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/listagem-wallet"
            component={EstablishmentListWallet}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/listagem-marketplace"
            component={EstablishmentListMktp}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/informacoes"
            component={EstablishmentInfo}
          />
          <PrivateRoute
            exact
            path="/estabelecimento-mktp/:id/informacoes"
            component={EstablishmentInfoMktp}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/:id/meios-pagamentos"
            component={PaymentMethods}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/cadastro/marca"
            component={BrandRegister}
          />
          <PrivateRoute
            exact
            path="/estabelecimento/marca/listagem"
            component={BrandList}
          />
          <PrivateRoute
            exact
            path="/logistics/delivery-register-fee"
            component={LogisticsPage}
          />
          <PrivateRoute
            exact
            path="/logistics/delivery-monitoring"
            component={DeliveryMonitoring}
          />
          <PrivateRoute
            exact
            path="/logistics/delivery-board"
            component={DeliveryBoard}
          />
          <PrivateRoute
            exact
            path="/transactions"
            component={TransactionsPage}
          />
          <PrivateRoute exact path="/consumidores" component={ConsumersList} />
          <PrivateRoute
            exact
            path="/consumidores/:id/detalhes"
            component={ConsumersDetails}
          />
          <PrivateRoute
            exact
            path="/operacoes/cadastro-usuario"
            component={UserRegistration}
          />
          <PrivateRoute
            exact
            path="/operacoes/usuarios"
            component={PortalUsers}
          />
          <PrivateRoute
            exact
            path="/pagamentos/config-master"
            component={PaymentsMasterList}
          />
          <PrivateRoute
            exact
            path="/pagamentos/config-master/edit/:id"
            component={PaymentsMasterEdit}
          />
        </Content>
      </div>
    </Container>
  );
}

export default PrivateRoutes;
