import { useOutsideClick, useUserContext } from '@/hooks';
import { useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

export function useHeader() {
  const [isVisibleDropDown, setIsVisibleDropDown] = useState(false);
  const { fullName, initials, email, logout } = useUserContext();
  const auth = getAuth();
  const [user] = useAuthState(auth);

  const menuRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsVisibleDropDown((prevState) => !prevState);
  };

  useOutsideClick(menuRef, handleToggle);

  return {
    menuRef,
    user,
    isVisibleDropDown,
    handleToggle,
    fullName,
    initials,
    email,
    logout,
  };
}
