import styled from 'styled-components';

import {
  Accordion,
  AccordionDetails,
  Stepper,
  Typography,
} from '@mui/material';

export const AccordionContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StepView = styled(Typography)<any>`
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: ${(props) => (props.isCurrentStep ? '14px' : '12px')} !important;

  background-color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.isCurrentStep
      ? '#ffffff'
      : props.isBeforeStep
      ? '#ca4376'
      : '#C1C1C6'};

  color: ${(props) => (props.isCurrentStep ? '#ca4376' : '#ffffff')};
  border: ${(props) => (props.isCurrentStep ? '1px solid #ca4376' : 'none')};

  border-radius: 50%;
  width: ${(props) => (props.isCurrentStep ? '24px' : '16px')};
  height: ${(props) => (props.isCurrentStep ? '24px' : '16px')};

  margin-left: ${(props) => (props.isCurrentStep ? '0px' : '4px')} !important;
  margin-right: 40px !important;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;

  transition: 0.2s;
`;

export const Title = styled(Typography)`
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;

  color: #28262c;
`;

export const StyledAccordion = styled(Accordion)`
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0px !important;

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: auto;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: transparent;
  margin-left: 70px;
`;

export const StyledStepper = styled(Stepper)``;

export const StepLine = styled.div<any>`
  position: absolute;
  height: ${(props) => props.height};
  border-left: 1px solid #ca4376;
  top: 28px;
  left: 28px;
  transition: 0.2s;
`;
