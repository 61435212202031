import * as React from 'react';

function EnableIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8.35449" cy="8" r="8" fill="#45BC66" />
      <g clipPath="url(#clip0_2738_3488)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.619 5.82285C11.7393 5.94863 11.7393 6.15256 11.619 6.27834L7.51445 10.5725C7.45671 10.6329 7.3784 10.6668 7.29675 10.6668C7.2151 10.6668 7.1368 10.6329 7.07906 10.5725L4.96712 8.36299C4.8469 8.23721 4.8469 8.03328 4.96712 7.9075C5.08735 7.78173 5.28228 7.78173 5.40251 7.9075L7.29675 9.88922L11.1836 5.82285C11.3039 5.69707 11.4988 5.69707 11.619 5.82285Z"
          fill="white"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2738_3488">
          <rect
            width="8.88889"
            height="7.11111"
            fill="white"
            transform="translate(3.90137 4.74072)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EnableIcon;
