import { StyledTypography } from './typography.styles';
import { IProps } from './typography.types';

function Typography({
  as,
  children,
  fontWeight,
  fontSize,
  lineHeight,
  className,
  color,
  props,
  style,
  htmlFor,
}: IProps) {
  return (
    <StyledTypography
      as={as}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      className={className}
      color={color}
      {...props}
      style={style}
      htmlFor={htmlFor}
    >
      {children}
    </StyledTypography>
  );
}

export default Typography;
