import { FormProvider } from 'react-hook-form';
import { SpinnerIcon } from '../../../../assets/icons';
import {
  CheckboxFieldReactHookForm as Checkbox,
  ErrorFieldReactHookForm as ErrorField,
  InputFieldReactHookForm as Input,
  Typography,
} from '../../../../components';
import { SubmitButton, ShowPasswordButton, FormItem, Box } from './form.styles';
import { IProps } from './form.types';

function LoginForm({
  formMethods,
  isLoading,
  handleInputType,
  passwordInputType,
  onSubmit,
}: IProps) {
  const {
    formState: { errors },
    handleSubmit,
  } = formMethods;

  return (
    <FormProvider {...formMethods} onSubmit={handleSubmit(onSubmit)}>
      <form>
        <FormItem hasError={errors.email?.message}>
          <Typography as="label">E-mail</Typography>
          <Input name="email" style={{ width: '302px', height: '40px' }} />
          <ErrorField name="email" />
        </FormItem>
        <FormItem className="mt-2" hasError={errors.password?.message}>
          <Box>
            <Typography as="label">Senha</Typography>
            <ShowPasswordButton type="button" onClick={handleInputType}>
              Exibir senha
            </ShowPasswordButton>
          </Box>
          <Input
            name="password"
            style={{ width: '302px', height: '40px' }}
            inputType={passwordInputType}
          />
          <ErrorField name="password" />
        </FormItem>
        <FormItem className="mt-2">
          <Checkbox
            label="Permanecer conectado"
            id="hasKeepConected"
            name="hasKeepConected"
          />
        </FormItem>
        <SubmitButton
          type="submit"
          className="mt-3"
          disabled={isLoading}
          onClick={onSubmit}
        >
          {isLoading ? (
            <SpinnerIcon fontSize={24} color="#fff" />
          ) : (
            <Typography>Entrar</Typography>
          )}
        </SubmitButton>
      </form>
    </FormProvider>
  );
}

export default LoginForm;
