export const setAuthInSessionStorage = (value: string) => {
  sessionStorage.setItem('auth', value);
};

export const setAuthInLocalStorage = (value: string) => {
  localStorage.setItem('auth', value);
};

export const getAuthFromSessionStorage = () => {
  const authToken = sessionStorage.getItem('auth');

  return authToken;
};
export const getAuthFromLocalStorage = () => {
  const authToken = localStorage.getItem('auth');

  return authToken;
};

export const clearAuthFromSessionStorage = () => {
  sessionStorage.removeItem('auth');
};

export const clearAuthFromLocalStorage = () => {
  localStorage.removeItem('auth');
};
