import * as React from 'react';

function CloseSuccessIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 5.79289C19.0976 6.18342 19.0976 6.81658 18.7071 7.20711L6.70711 19.2071C6.31658 19.5976 5.68342 19.5976 5.29289 19.2071C4.90237 18.8166 4.90237 18.1834 5.29289 17.7929L17.2929 5.79289C17.6834 5.40237 18.3166 5.40237 18.7071 5.79289Z"
        fill="#15803D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 5.79289C5.68342 5.40237 6.31658 5.40237 6.70711 5.79289L18.7071 17.7929C19.0976 18.1834 19.0976 18.8166 18.7071 19.2071C18.3166 19.5976 17.6834 19.5976 17.2929 19.2071L5.29289 7.20711C4.90237 6.81658 4.90237 6.18342 5.29289 5.79289Z"
        fill="#15803D"
      />
    </svg>
  );
}

export default CloseSuccessIcon;
