import * as React from 'react';

function UserIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 20 20"
      version="1.1"
    >
      <g id="surface1">
        <path d="M 8 7.984375 C 7.265625 7.984375 6.667969 7.75 6.199219 7.285156 C 5.734375 6.816406 5.5 6.214844 5.5 5.484375 C 5.5 4.75 5.734375 4.148438 6.199219 3.683594 C 6.667969 3.214844 7.265625 2.984375 8 2.984375 C 8.734375 2.984375 9.332031 3.214844 9.800781 3.683594 C 10.265625 4.148438 10.5 4.75 10.5 5.484375 C 10.5 6.214844 10.265625 6.816406 9.800781 7.285156 C 9.332031 7.75 8.734375 7.984375 8 7.984375 Z M 2.667969 13.332031 L 2.667969 11.765625 C 2.667969 11.34375 2.773438 10.984375 2.984375 10.683594 C 3.195312 10.382812 3.464844 10.15625 3.800781 10 C 4.542969 9.667969 5.257812 9.417969 5.941406 9.25 C 6.625 9.082031 7.3125 9 8 9 C 8.6875 9 9.371094 9.085938 10.050781 9.257812 C 10.726562 9.429688 11.4375 9.679688 12.183594 10 C 12.527344 10.15625 12.804688 10.382812 13.015625 10.683594 C 13.226562 10.984375 13.332031 11.34375 13.332031 11.765625 L 13.332031 13.332031 Z M 3.667969 12.332031 L 12.332031 12.332031 L 12.332031 11.765625 C 12.332031 11.589844 12.28125 11.417969 12.175781 11.257812 C 12.070312 11.097656 11.9375 10.976562 11.785156 10.898438 C 11.070312 10.554688 10.421875 10.320312 9.832031 10.191406 C 9.246094 10.0625 8.632812 10 8 10 C 7.367188 10 6.75 10.0625 6.148438 10.191406 C 5.550781 10.320312 4.898438 10.554688 4.199219 10.898438 C 4.042969 10.976562 3.917969 11.097656 3.816406 11.257812 C 3.714844 11.417969 3.667969 11.589844 3.667969 11.765625 Z M 8 6.984375 C 8.433594 6.984375 8.792969 6.839844 9.074219 6.558594 C 9.359375 6.273438 9.5 5.917969 9.5 5.484375 C 9.5 5.050781 9.359375 4.691406 9.074219 4.410156 C 8.792969 4.125 8.433594 3.984375 8 3.984375 C 7.566406 3.984375 7.207031 4.125 6.925781 4.410156 C 6.640625 4.691406 6.5 5.050781 6.5 5.484375 C 6.5 5.917969 6.640625 6.273438 6.925781 6.558594 C 7.207031 6.839844 7.566406 6.984375 8 6.984375 Z M 8 5.484375 Z M 8 12.332031 Z M 8 12.332031 " />
      </g>
    </svg>
  );
}

export default UserIcon;
