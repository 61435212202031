import * as React from 'react';

function Desktop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V15C22 16.1046 21.1046 17 20 17H4C2.89543 17 2 16.1046 2 15V5ZM20 5L4 5V15H20V5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 20C6 19.4477 6.44772 19 7 19H17C17.5523 19 18 19.4477 18 20C18 20.5523 17.5523 21 17 21H7C6.44772 21 6 20.5523 6 20Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15C9.55228 15 10 15.4477 10 16V20C10 20.5523 9.55228 21 9 21C8.44772 21 8 20.5523 8 20V16C8 15.4477 8.44772 15 9 15Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 15C15.5523 15 16 15.4477 16 16V20C16 20.5523 15.5523 21 15 21C14.4477 21 14 20.5523 14 20V16C14 15.4477 14.4477 15 15 15Z"
        fill="black"
      />
    </svg>
  );
}

export default Desktop;
