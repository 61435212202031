export const firebaseConfig = {
  apiKey: 'AIzaSyCPmHJwSgcIMwlgNVo9W7ZA1MfsuIKUVwk',
  authDomain: 'brandapplication.firebaseapp.com',
  databaseURL: 'https://brandapplication.firebaseio.com',
  projectId: 'brandapplication',
  storageBucket: 'brandapplication.appspot.com',
  messagingSenderId: '240285417545',
  appId: '1:240285417545:web:922ac1cb970b642afc7f98',
  measurementId: 'G-E47JMXS3KX',
};
