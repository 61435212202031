import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
`;

export const ItemsBoxPerPage = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
`;

export const ItemsPerPage = styled.div`
  height: 40px;
  width: 69px;
  border-radius: 4px;
  border: 1px solid #ece6f0;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 8px;
`;

export const SizeOptionsBox = styled.div`
  width: 69px;
  border-radius: 4px;
  border: 1px solid #ece6f0;
  border-bottom: none;
  position: relative;
  top: -129px;
  left: 111px;
  background: #fbf9fc;
  cursor: pointer;
`;

export const ItemSize = styled.div`
  text-align: center;

  &:hover {
    background: #6a2cb1;
    color: #fff;
  }
`;
