import * as React from 'react';

function AlertCicleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 2.66659C5.05464 2.66659 2.66683 5.0544 2.66683 7.99992C2.66683 10.9454 5.05464 13.3333 8.00016 13.3333C10.9457 13.3333 13.3335 10.9454 13.3335 7.99992C13.3335 5.0544 10.9457 2.66659 8.00016 2.66659ZM1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992Z"
        fill="#B45309"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 4.66675C8.36835 4.66675 8.66683 4.96522 8.66683 5.33341V8.00008C8.66683 8.36827 8.36835 8.66675 8.00016 8.66675C7.63197 8.66675 7.3335 8.36827 7.3335 8.00008V5.33341C7.3335 4.96522 7.63197 4.66675 8.00016 4.66675Z"
        fill="#B45309"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3335 10.6667C7.3335 10.2985 7.63197 10 8.00016 10H8.00683C8.37502 10 8.6735 10.2985 8.6735 10.6667C8.6735 11.0349 8.37502 11.3333 8.00683 11.3333H8.00016C7.63197 11.3333 7.3335 11.0349 7.3335 10.6667Z"
        fill="#B45309"
      />
    </svg>
  );
}

export default AlertCicleIcon;
