import { useFormContext } from 'react-hook-form';
import { SelectStyled } from './select-field-react-hook-form.styles';
import { IProps } from './select-field-react-hook-form.types';

function Select({ options, name, disabled }: IProps) {
  const { register } = useFormContext();

  return (
    <SelectStyled {...register(name)} disabled={disabled}>
      {options?.map((option: any) => (
        <option key={option?.description} value={option?.value}>
          {option?.description?.toUpperCase()}
        </option>
      ))}
    </SelectStyled>
  );
}

export default Select;
