import * as React from 'react';

function LogisticsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8334 5.83341C15.8334 4.91675 15.0834 4.16675 14.1667 4.16675H11.6667V5.83341H14.1667V8.04175L11.2667 11.6667H8.33335V7.50008H5.00002C3.15835 7.50008 1.66669 8.99175 1.66669 10.8334V13.3334H3.33335C3.33335 14.7167 4.45002 15.8334 5.83335 15.8334C7.21669 15.8334 8.33335 14.7167 8.33335 13.3334H12.0667L15.8334 8.62508V5.83341ZM5.83335 14.1667C5.37502 14.1667 5.00002 13.7917 5.00002 13.3334H6.66669C6.66669 13.7917 6.29169 14.1667 5.83335 14.1667Z"
        fill="#35007D"
      />
      <path
        d="M4.16669 5H8.33335V6.66667H4.16669V5ZM15.8334 10.8333C14.45 10.8333 13.3334 11.95 13.3334 13.3333C13.3334 14.7167 14.45 15.8333 15.8334 15.8333C17.2167 15.8333 18.3334 14.7167 18.3334 13.3333C18.3334 11.95 17.2167 10.8333 15.8334 10.8333ZM15.8334 14.1667C15.375 14.1667 15 13.7917 15 13.3333C15 12.875 15.375 12.5 15.8334 12.5C16.2917 12.5 16.6667 12.875 16.6667 13.3333C16.6667 13.7917 16.2917 14.1667 15.8334 14.1667Z"
        fill="#35007D"
      />
    </svg>
  );
}

export default LogisticsIcon;
