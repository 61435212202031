import styled from 'styled-components';

export const Content = styled.div`
  width: 337px;
  border: 0.5px solid #c4c4c4;
  border-radius: 11px;
  min-height: 61px;
  padding: 18px;
  letter-spacing: 0.004em;
`;

export const Ellipse = styled.div`
  font-size: 11px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #35007d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 8px;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
`;

export const Link = styled.a`
  margin-left: 4px;
`;
