import { useFormContext } from 'react-hook-form';
import { StyledInput } from './input-field-react-hook-form';
import { IProps } from './input-field-react-hook-form.types';

function Input({ name, style, inputType, onBlur, onFocus }: IProps) {
  const { register } = useFormContext();
  return (
    <StyledInput
      type={inputType || 'text'}
      {...register(name)}
      style={style}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}

export default Input;
