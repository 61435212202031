import React from 'react';
import { Content, BreadcumbItem } from './breadcrumb.styles';
import { IProps } from './breadcumb.types';
import { ArrowRightIcon } from '../../assets/icons';

function Breadcrumb({ breadcumbItems }: IProps) {
  return (
    <Content>
      {breadcumbItems.map((breadcumbItem, index) => {
        const isLastItem = breadcumbItems.length === index + 1;
        return (
          <React.Fragment key={breadcumbItem}>
            <BreadcumbItem isLastItem={isLastItem}>
              {breadcumbItem}
            </BreadcumbItem>
            {isLastItem ? null : <ArrowRightIcon />}
          </React.Fragment>
        );
      })}
    </Content>
  );
}
export default Breadcrumb;
