import { Container, Content, Divider } from './login.styles';
import Branding from '../../assets/pp-branding.png';
import useLogin from './login.hook';
import LoginForm from './components/Form/form.component';
import FirebaseAuth from './components/FirebaseAuth/firebase-auth.component';
import RecoveryPasswordInstruction from './components/RecoveryPasswordInstructions/recovery-password-instructions.component';

function Login() {
  const {
    formMethods,
    passwordInputType,
    isLoading,
    onSubmit,
    handleInputType,
    handleToggle,
    toggleRecoveryPasswordInstruction,
  } = useLogin();

  return (
    <Container>
      <Content className="pt-4">
        <img src={Branding} width="129px" height="44px" alt="Logo" />
        <LoginForm
          formMethods={formMethods}
          isLoading={isLoading}
          handleInputType={handleInputType}
          passwordInputType={passwordInputType}
          onSubmit={onSubmit}
        />
        <FirebaseAuth />
        <Divider className="mt-3" />
        <RecoveryPasswordInstruction
          toggle={toggleRecoveryPasswordInstruction}
          handleToggle={handleToggle}
        />
      </Content>
    </Container>
  );
}
export default Login;
