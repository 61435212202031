export const getFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
) => {
  if (!firstName || !lastName) {
    return '';
  }

  const fullName = `${firstName} ${lastName}`;
  return fullName;
};

export const getInitialFromFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
) => {
  if (!firstName || !lastName) {
    return '';
  }

  const firstNameInitial = firstName?.[0].toUpperCase();
  const lastNameInitial = lastName?.[0].toUpperCase();
  return `${firstNameInitial}${lastNameInitial}`;
};

export const getEmail = (userEmail: string | undefined) => {
  if (!userEmail) {
    return '';
  }

  const email = userEmail;
  return email;
};
