import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Typography } from 'antd';
import { useStyles } from './modal.styles';

function Modal({ title, content, open, onConfirm, onCancel }: any) {
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(open);
  }, [open]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {showModal && (
        <Box className={styles.container}>
          <Typography className={styles.title}>{title}</Typography>
          <Typography className={styles.content}>{content}</Typography>

          <Box className={styles.footer}>
            <Button
              type="button"
              className={styles.btnPrimary}
              onClick={onConfirm}
            >
              Sim
            </Button>

            <Button
              type="button"
              className={styles.btnSecondary}
              onClick={onCancel}
            >
              Não
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Modal;
