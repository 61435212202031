import styled from 'styled-components';

export const SubmitButton = styled.button`
  width: 302px;
  height: 40px;
  background: #6a2cb1;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? '0.8' : '1')};
  :hover {
    opacity: 0.8;
    transition: 0.5s ease-in-out;
  }
`;

export const ShowPasswordButton = styled.button`
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #9856d8;
  background: transparent;
`;

export const FormItem = styled.div<{ hasError?: boolean }>`
  width: 302px;
  position: relative;

  label {
    display: inline;
    color: ${(props) => (props.hasError ? '#dd3344' : '#61596C')};
  }

  input {
    border: ${(props) =>
      props.hasError ? ' 1px solid #dd3344' : '1px solid #ece6f0'};
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
