import { useRef, useCallback } from 'react';

export default function useDebounce(fn: any, delay: number) {
  const timeoutRef = useRef<any>(null);

  const debouncedFn = useCallback(
    (...args: any) => {
      clearTimeout(timeoutRef.current as any);
      timeoutRef.current = setTimeout(() => {
        fn(...args);
      }, delay);
    },
    [delay, fn],
  );

  return debouncedFn;
}
