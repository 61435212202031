import styled from 'styled-components';

export const SelectStyled = styled.select`
  height: 40px;
  width: 307px;
  border-radius: 4px;
  border: 1px solid #ece6f0;
  text-align: center;
  cursor: pointer;
`;
