import * as React from 'react';

function MicrosoftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 10 10"
      version="1.1"
    >
      <g id="surface1">
        <path
          style={{ fill: 'rgb(94.509804%,31.764706%,10.588235%)' }}
          d="M 4.753906 4.75 L 0 4.75 L 0 0 L 4.753906 0 Z M 4.753906 4.75 "
        />
        <path
          style={{ fill: 'rgb(50.196078%,80%,15.686275%)' }}
          d="M 10 4.75 L 5.246094 4.75 L 5.246094 0 L 10 0 Z M 10 4.75 "
        />
        <path
          style={{ fill: 'rgb(0%,67.843137%,93.72549%)' }}
          d="M 4.753906 10 L 0 10 L 0 5.25 L 4.753906 5.25 Z M 4.753906 10 "
        />
        <path
          style={{ fill: 'rgb(98.431373%,73.72549%,3.529412%)' }}
          d="M 10 10 L 5.246094 10 L 5.246094 5.25 L 10 5.25 Z M 10 10 "
        />
      </g>
    </svg>
  );
}

export default MicrosoftIcon;
