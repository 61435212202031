import { useFormContext } from 'react-hook-form';
import { IProps } from './error-field-react-hook-form.types';
import Typography from '../Typography/typography.component';

function ErrorFieldReactHookForm({ className, name }: IProps) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      {errors?.[name] && (
        <Typography
          className={className}
          fontSize="12"
          lineHeight="20px"
          color="#DD3344"
        >
          {errors[name]?.message}
        </Typography>
      )}
    </div>
  );
}

export default ErrorFieldReactHookForm;
