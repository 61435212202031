import * as React from 'react';

function TransactionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8798 8.00078C10.8798 7.60314 11.2022 7.28078 11.5998 7.28078H14.4798C14.8774 7.28078 15.1998 7.60314 15.1998 8.00078V13.0408C15.1998 14.2337 14.2327 15.2008 13.0398 15.2008H11.5998C11.2022 15.2008 10.8798 14.8784 10.8798 14.4808V8.00078ZM12.3198 8.72078V13.7608H13.0398C13.4374 13.7608 13.7598 13.4384 13.7598 13.0408V8.72078H12.3198Z"
        fill="#35007D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.15842 0.898046C1.3804 0.769222 1.65418 0.768308 1.87702 0.895646L4.0398 2.13152L6.20258 0.895646C6.42394 0.76916 6.69567 0.76916 6.91703 0.895646L9.0798 2.13152L11.2426 0.895646C11.4654 0.768308 11.7392 0.769222 11.9612 0.898046C12.1832 1.02687 12.3198 1.26412 12.3198 1.52078V14.4808C12.3198 14.8784 11.9974 15.2008 11.5998 15.2008H3.6798C2.08922 15.2008 0.799805 13.9114 0.799805 12.3208V1.52078C0.799805 1.26412 0.936431 1.02687 1.15842 0.898046ZM2.2398 2.76147V12.3208C2.2398 13.1161 2.88451 13.7608 3.6798 13.7608H10.8798V2.76147L9.43702 3.58592C9.21567 3.7124 8.94394 3.7124 8.72258 3.58592L6.5598 2.35004L4.39702 3.58592C4.17567 3.7124 3.90394 3.7124 3.68258 3.58592L2.2398 2.76147Z"
        fill="#35007D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.67969 5.84109C3.67969 5.44345 4.00204 5.12109 4.39969 5.12109L7.27969 5.12109C7.67733 5.12109 7.99969 5.44345 7.99969 5.84109C7.99969 6.23874 7.67733 6.56109 7.27969 6.56109L4.39969 6.56109C4.00204 6.56109 3.67969 6.23874 3.67969 5.84109Z"
        fill="#35007D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83984 8.72C5.83984 8.32235 6.1622 8 6.55984 8L8.71984 8C9.11749 8 9.43984 8.32235 9.43984 8.72C9.43984 9.11764 9.11749 9.44 8.71984 9.44H6.55984C6.1622 9.44 5.83984 9.11764 5.83984 8.72Z"
        fill="#35007D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83984 11.5989C5.83984 11.2013 6.1622 10.8789 6.55984 10.8789H8.71984C9.11749 10.8789 9.43984 11.2013 9.43984 11.5989C9.43984 11.9966 9.11749 12.3189 8.71984 12.3189H6.55984C6.1622 12.3189 5.83984 11.9966 5.83984 11.5989Z"
        fill="#35007D"
      />
      <path
        d="M5.11969 8.72C5.11969 9.11764 4.79733 9.44 4.39969 9.44C4.00204 9.44 3.67969 9.11764 3.67969 8.72C3.67969 8.32235 4.00204 8 4.39969 8C4.79733 8 5.11969 8.32235 5.11969 8.72Z"
        fill="#35007D"
      />
      <path
        d="M5.11969 11.5989C5.11969 11.9966 4.79733 12.3189 4.39969 12.3189C4.00204 12.3189 3.67969 11.9966 3.67969 11.5989C3.67969 11.2013 4.00204 10.8789 4.39969 10.8789C4.79733 10.8789 5.11969 11.2013 5.11969 11.5989Z"
        fill="#35007D"
      />
    </svg>
  );
}

export default TransactionIcon;
