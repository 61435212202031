import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #28006b;
  height: 100vh;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 505px;
  width: 464px;
  border-radius: 16px;
  background: #fbf9fc;
`;

export const Divider = styled.div`
  width: 337px;
  border-bottom: 1px solid #ece6f0;
`;
