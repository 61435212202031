import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';

import { schemaValidation } from './login.fields';
import {
  setAuthInSessionStorage,
  setAuthInLocalStorage,
} from '../../util/storage';
import { ENDPOINTS } from '../../endpoints';
import api from '../../config/api';

const useLogin = () => {
  const [passwordInputType, setPasswordInpuType] = useState('password');
  const [
    toggleRecoveryPasswordInstruction,
    setToggleRecoveryPasswordInstruction,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const handleInputType = () => {
    setPasswordInpuType((value) =>
      value === 'password' ? 'text' : 'password',
    );
  };

  const handleToggle = () => {
    setToggleRecoveryPasswordInstruction((prevState) => !prevState);
  };

  const formMethods = useForm({
    resolver: yupResolver(schemaValidation),
  });

  const { getValues, trigger, setError } = formMethods;

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const isValid = await trigger();
      if (!isValid) return;

      const { email: login, password, hasKeepConected } = getValues();
      const payload = {
        login,
        password,
      };

      const response = await api.post(ENDPOINTS.login.post, payload);

      if (response.status === 200) {
        const { token, tokenType } = await response.data;
        const authToken = `${tokenType} ${token}`;

        hasKeepConected
          ? setAuthInLocalStorage(authToken)
          : setAuthInSessionStorage(authToken);
        history.push('/estabelecimento/listagem-wallet');
      }

      if (response.status === 404) {
        const { message } = await response.data;
        setError('email', { type: 'manual', message });
      }

      if (response.status === 401) {
        setError('password', { type: 'manual', message: 'Senha inválida.' });
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return {
    formMethods,
    onSubmit,
    passwordInputType,
    handleInputType,
    handleToggle,
    toggleRecoveryPasswordInstruction,
    isLoading,
  };
};

export default useLogin;
