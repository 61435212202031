import * as React from 'react';

function CutleryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9289 1.38719C13.1741 1.49218 13.3332 1.73327 13.3332 2.00006V14.0001C13.3332 14.3682 13.0347 14.6667 12.6665 14.6667H11.9998C11.6316 14.6667 11.3332 14.3682 11.3332 14.0001V12.0001C11.3332 11.6319 11.6316 11.3334 11.9998 11.3334V10.6667H9.33317C8.96661 10.6667 8.66881 10.3708 8.66652 10.0042C8.65879 8.76808 8.68665 7.43983 9.15721 6.01874C9.631 4.58793 10.5357 3.11128 12.2063 1.51767C12.3994 1.33352 12.6836 1.2822 12.9289 1.38719ZM11.9998 9.33339H10.0018C10.0167 8.35812 10.0985 7.41778 10.423 6.43787C10.706 5.58305 11.1829 4.67336 11.9998 3.68421V9.33339ZM3.33317 2.00006C3.70136 2.00006 3.99984 2.29853 3.99984 2.66672V4.66672C3.99984 5.16025 4.26797 5.59114 4.6665 5.82168V2.66672C4.6665 2.29853 4.96498 2.00006 5.33317 2.00006C5.70136 2.00006 5.99984 2.29853 5.99984 2.66672V5.82168C6.39837 5.59114 6.6665 5.16025 6.6665 4.66672V2.66672C6.6665 2.29853 6.96498 2.00006 7.33317 2.00006C7.70136 2.00006 7.99984 2.29853 7.99984 2.66672V4.66672C7.99984 5.90928 7.14999 6.95335 5.99984 7.24938V14.0001C5.99984 14.3682 5.70136 14.6667 5.33317 14.6667C4.96498 14.6667 4.6665 14.3682 4.6665 14.0001V7.24938C3.51635 6.95335 2.6665 5.90928 2.6665 4.66672V2.66672C2.6665 2.29853 2.96498 2.00006 3.33317 2.00006Z"
        fill="#908A99"
      />
    </svg>
  );
}

export default CutleryIcon;
