import * as React from 'react';

function ArrowSortedDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="6"
      height="5"
      viewBox="0 0 6 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 5L0.401924 0.5L5.59808 0.5L3 5Z" fill="#454545" />
    </svg>
  );
}

export default ArrowSortedDownIcon;
