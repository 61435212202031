/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
export const getAddressString = (deliveryAddress: any) => {
  let address = '';

  if (!deliveryAddress) {
    return '-';
  }

  if (deliveryAddress.street) {
    address += `${deliveryAddress.street}, `;
  }

  if (deliveryAddress.number) {
    address += `Nº ${deliveryAddress.number}, `;
  }

  if (deliveryAddress.city) {
    address += `${deliveryAddress.city}/`;
  }

  if (deliveryAddress.state) {
    address += `${deliveryAddress.state} - `;
  }

  if (deliveryAddress.postalCode) {
    address += `${deliveryAddress.postalCode}`;
  }

  return address || '-';
};

export const getAddressObject = (address_components: any[]) => {
  let country = '';
  let state = '';
  let city = '';
  let street = '';
  let number = '';
  let postalCode = '';
  let complement = '';

  if (!address_components) {
    return null;
  }

  for (const component of address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types[0];

    if (componentType === 'street_number') {
      number = component.long_name;
    }

    if (componentType === 'route') {
      street = component.long_name;
    }

    if (componentType === 'administrative_area_level_2') {
      city = component.long_name;
    }

    if (componentType === 'administrative_area_level_1') {
      state = component.long_name;
    }

    if (componentType === 'country') {
      country = component.long_name;
    }

    if (componentType === 'postal_code') {
      postalCode = component.long_name;
    }

    if (componentType === 'subpremise') {
      complement = component.long_name;
    }

    if (componentType === 'postal_code_suffix') {
      postalCode = `${postalCode}-${component.long_name}`;
    }

    if (componentType === 'sublocality_level_1') {
      complement = component.long_name;
    }

    if (componentType === 'sublocality_level_2') {
      complement += ` ${component.long_name}`;
    }

    if (componentType === 'sublocality_level_3') {
      complement += ` ${component.long_name}`;
    }
  }

  return {
    country,
    state,
    city,
    street,
    number,
    postalCode,
    complement,
  };
};
