import {
  ArrowSortedDownIcon,
  ArrowSortedUpIcon,
} from '../../../../assets/icons';
import { Typography } from '../../../../components';
import {
  Content,
  Box,
  Ellipse,
  ToggleBox,
  Link,
} from './recovery-password-instruction.styles';

function RecoveryPasswordInstructions({ toggle, handleToggle }: any) {
  return (
    <Content className="mt-3 mb-3">
      <Box>
        <Ellipse>!</Ellipse>
        <Typography fontSize="12" lineHeight="20px" color="#8E8797">
          Veja como recuperar sua senha.
        </Typography>
      </Box>
      <ToggleBox onClick={handleToggle}>
        {toggle ? <ArrowSortedUpIcon /> : <ArrowSortedDownIcon />}
        <Typography fontSize="10" lineHeight="20px" color="#8E8797">
          {(toggle && 'Ver menos...') || 'Ver mais...'}
        </Typography>
      </ToggleBox>
      {toggle && (
        <>
          <Typography
            className="mt-1"
            fontSize="12"
            lineHeight="12px"
            color="#8E8797"
          >
            Para alteração dessa senha você deve acessar o seguinte link:
            <Link href="https://admin.pedepronto.com.br" target="_blank">
              https://admin.pedepronto.com.br
            </Link>
          </Typography>
          <Typography
            className="mt-3"
            fontSize="12"
            lineHeight="12px"
            color="#8E8797"
          >
            Lembre-se que alterando essa senha você altera todas as outras.
          </Typography>
        </>
      )}
    </Content>
  );
}

export default RecoveryPasswordInstructions;
