import React from 'react';
import { AccordionSummary } from '@mui/material';

import ConfirmIcon from '@/assets/icons/Confirm';
import {
  AccordionContainer,
  StepLine,
  StepView,
  StyledAccordion,
  StyledAccordionDetails,
  Title,
} from './accordion-stepper.styles';

function AccordionStepper({ steps, activeStep }: any) {
  return (
    <AccordionContainer>
      {steps?.map((step: any, index: number) => (
        <StyledAccordion key={index} expanded={activeStep === index}>
          <AccordionSummary
            expandIcon={null}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <StepView
              isCurrentStep={index === activeStep}
              isBeforeStep={activeStep > index}
            >
              {activeStep > index ? <ConfirmIcon /> : index + 1}
            </StepView>
            <Title>{step.title}</Title>
          </AccordionSummary>

          <StyledAccordionDetails>{step.component}</StyledAccordionDetails>
        </StyledAccordion>
      ))}

      <StepLine
        height={
          steps.length - 1 === activeStep
            ? `${48 * (steps.length - 1)}px`
            : 'calc(100% - 48px)'
        }
      />
    </AccordionContainer>
  );
}

export default AccordionStepper;
