import Typography from '../Typography/typography.component';
import { IProps } from './error-field.types';

function ErrorField({ className, errorMessage }: IProps) {
  return (
    <Typography
      className={className}
      fontSize="12"
      lineHeight="20px"
      color="#DD3344"
    >
      {errorMessage}
    </Typography>
  );
}

export default ErrorField;
