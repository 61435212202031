import { Link, useHistory } from 'react-router-dom';
import { memo, useState } from 'react';
import { Typography } from '@/components';
import Modal from '../../../../components/Modal/modal.component';
import { NavItemSubPaths, NavList, NavItem, Box } from './nested-paths.styles';
import { INestedPaths } from './nested-paths.types';

function NestedPaths({ paths, isActive }: any) {
  const history = useHistory();

  const [
    openOperationsUserRegistrationModal,
    setOpenOperationsUserRegistrationModal,
  ] = useState(false);

  const [clickedRoute, setClickedRoute] = useState('');

  function handleOptionClick(event: any, path: string) {
    const hasUserValuePopulated = localStorage.getItem(
      'hasUserValuePopulated',
    ) as string;

    if (!JSON.parse(hasUserValuePopulated)) {
      return;
    }

    setClickedRoute(path);
    event.preventDefault();
    setOpenOperationsUserRegistrationModal(true);
  }

  function handleConfirm() {
    setOpenOperationsUserRegistrationModal(false);
    localStorage.removeItem('hasUserValuePopulated');

    if (clickedRoute === '/operacoes/cadastro-usuario') {
      window.location.reload();
      return;
    }

    history.push(clickedRoute);
  }

  function handleCancel() {
    setOpenOperationsUserRegistrationModal(false);
  }

  return (
    <>
      <NavItemSubPaths isActive={isActive}>
        <Box>
          {paths?.icon}
          <Typography
            className="ml-1"
            fontSize="14"
            lineHeight="20px"
            fontWeight="600"
          >
            {paths?.name}
          </Typography>
        </Box>
        <NavList className="ml-4">
          {paths?.nestedPaths.map(({ name, path }: INestedPaths) => {
            return (
              <NavItem key={path}>
                <Link to={path} onClick={(e) => handleOptionClick(e, path)}>
                  <Typography
                    fontWeight={isActive ? '600' : '400'}
                    fontSize="12"
                    color="#625A6A"
                  >
                    {name}
                  </Typography>
                </Link>
              </NavItem>
            );
          })}
        </NavList>
      </NavItemSubPaths>

      <Modal
        title="Deseja realmente sair da página?"
        content="As informações poderão ser perdidas!"
        open={openOperationsUserRegistrationModal}
        onConfirm={() => handleConfirm()}
        onCancel={() => handleCancel()}
      />
    </>
  );
}

export default memo(NestedPaths);
