import { ArrowDownIcon, ArrowUpIcon } from '@/assets/icons';
import { Typography } from '@/components';
import {
  HeaderS,
  Branding,
  Avatar,
  UserBox,
  UserName,
  Divider,
  DropDown,
  DropwDownContainer,
  Box,
  Arrow,
  ArrowBox,
  LogoutButton,
} from './header.styles';
import branding from '../../assets/pp-branding.png';
import { useHeader } from './header.hook';

export function Header() {
  const {
    menuRef,
    user,
    isVisibleDropDown,
    handleToggle,
    fullName,
    initials,
    email,
    logout,
  } = useHeader();

  return (
    <>
      <HeaderS>
        <Branding src={branding} alt="branding" />
        <UserBox>
          <Avatar>{initials || user?.displayName?.at(0)}</Avatar>
          <UserName>{fullName || user?.displayName}</UserName>
          <ArrowBox onClick={handleToggle}>
            {isVisibleDropDown ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </ArrowBox>
        </UserBox>
      </HeaderS>
      {isVisibleDropDown && (
        <DropwDownContainer ref={menuRef}>
          <DropDown>
            <Arrow />
            <Box>
              <Typography
                fontSize="16"
                color="#6A2CB1"
                fontWeight="600"
                lineHeight="24px"
              >
                {fullName || user?.displayName}
              </Typography>
              <Typography
                fontSize="14"
                color=""
                fontWeight="400"
                lineHeight="16px"
              >
                {email || user?.email}
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Typography
                fontSize="12"
                lineHeight="16px"
                fontWeight="400"
                color="#8E8797"
              >
                Minha conta
              </Typography>
              <Typography fontSize="12" lineHeight="20px" fontWeight="600">
                Dados cadastrais
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize="12"
                lineHeight="16px"
                fontWeight="400"
                color="#8E8797"
              >
                Geral
              </Typography>
              <LogoutButton type="button" onClick={logout}>
                Sair
              </LogoutButton>
            </Box>
          </DropDown>
        </DropwDownContainer>
      )}
    </>
  );
}
