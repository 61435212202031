import * as React from 'react';

function EditPaperIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group">
        <path
          id="Path (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8C0 6.34315 1.34315 5 3 5H6C6.55228 5 7 5.44772 7 6C7 6.55228 6.55228 7 6 7H3C2.44772 7 2 7.44772 2 8V17C2 17.5523 2.44772 18 3 18H12C12.5523 18 13 17.5523 13 17V14C13 13.4477 13.4477 13 14 13C14.5523 13 15 13.4477 15 14V17C15 18.6569 13.6569 20 12 20H3C1.34315 20 0 18.6569 0 17V8Z"
          fill="#CA4376"
        />
        <path
          id="Path (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7929 1.79288C15.0118 0.573924 16.9882 0.573924 18.2071 1.79288C19.4261 3.01183 19.4261 4.98814 18.2071 6.20709L9.70711 14.7071C9.51957 14.8946 9.26522 15 9 15H6C5.44772 15 5 14.5523 5 14V11C5 10.7348 5.10536 10.4804 5.29289 10.2929L13.7929 1.79288ZM7 13H8.58579L16.7929 4.79288C17.2308 4.35497 17.2308 3.64499 16.7929 3.20709C16.355 2.76919 15.645 2.76919 15.2071 3.20709L7 11.4142V13Z"
          fill="#CA4376"
        />
        <path
          id="Path (Stroke)_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2929 3.29289C12.6834 2.90237 13.3166 2.90237 13.7071 3.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L12.2929 4.70711C11.9024 4.31658 11.9024 3.68342 12.2929 3.29289Z"
          fill="#CA4376"
        />
      </g>
    </svg>
  );
}

export default EditPaperIcon;
