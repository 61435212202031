import styled from 'styled-components';

export const StyledNavigationBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  padding-left: 16px;
  border-left: 1px solid #ece6f0;
  border-right: 1px solid #ece6f0;
  border-bottom: 1px solid #ece6f0;
  height: 72px;
  background: #fff;
`;
