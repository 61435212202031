import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Loading({ color }: any) {
  return (
    <Backdrop sx={{ color: color || '#35007d' }} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loading;
