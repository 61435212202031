import * as React from 'react';

function ConfirmIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90237 1.09763C6.03255 1.22781 6.03255 1.43886 5.90237 1.56904L2.56904 4.90237C2.43886 5.03254 2.22781 5.03254 2.09763 4.90237L0.430965 3.2357C0.30079 3.10553 0.30079 2.89447 0.430965 2.7643C0.56114 2.63412 0.772195 2.63412 0.90237 2.7643L2.33333 4.19526L5.43097 1.09763C5.56114 0.967456 5.7722 0.967456 5.90237 1.09763Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ConfirmIcon;
