import * as React from 'react';

function ModalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.54297 3.95833C2.35511 3.95833 2.17494 4.03296 2.0421 4.1658C1.90926 4.29864 1.83464 4.47881 1.83464 4.66667V12.4583C1.83464 12.6462 1.90926 12.8264 2.0421 12.9592C2.17494 13.092 2.35511 13.1667 2.54297 13.1667H10.3346C10.5225 13.1667 10.7027 13.092 10.8355 12.9592C10.9683 12.8264 11.043 12.6462 11.043 12.4583V8.20833C11.043 7.81713 11.3601 7.5 11.7513 7.5C12.1425 7.5 12.4596 7.81713 12.4596 8.20833V12.4583C12.4596 13.0219 12.2358 13.5624 11.8372 13.9609C11.4387 14.3595 10.8982 14.5833 10.3346 14.5833H2.54297C1.97938 14.5833 1.43888 14.3595 1.04037 13.9609C0.641852 13.5624 0.417969 13.0219 0.417969 12.4583V4.66667C0.417969 4.10308 0.641852 3.56258 1.04037 3.16407C1.43888 2.76555 1.97938 2.54167 2.54297 2.54167H6.79297C7.18417 2.54167 7.5013 2.8588 7.5013 3.25C7.5013 3.6412 7.18417 3.95833 6.79297 3.95833H2.54297Z"
        fill="#35007D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.91797 1.125C8.91797 0.7338 9.2351 0.416668 9.6263 0.416668H13.8763C14.2675 0.416668 14.5846 0.7338 14.5846 1.125V5.375C14.5846 5.7662 14.2675 6.08333 13.8763 6.08333C13.4851 6.08333 13.168 5.7662 13.168 5.375V1.83333H9.6263C9.2351 1.83333 8.91797 1.5162 8.91797 1.125Z"
        fill="#35007D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3772 0.624134C14.6538 0.900755 14.6538 1.34925 14.3772 1.62587L6.5855 9.41754C6.30888 9.69416 5.86039 9.69416 5.58377 9.41754C5.30715 9.14091 5.30715 8.69242 5.58377 8.4158L13.3754 0.624134C13.6521 0.347513 14.1005 0.347513 14.3772 0.624134Z"
        fill="#35007D"
      />
    </svg>
  );
}

export default ModalIcon;
