const BASE_URL_MOCK =
  'https://18aef573-8680-4819-ba83-e87f631c96c0.mock.pstmn.io';
const BASE_URL_NEW_MOCK =
  'https://my-json-server.typicode.com/Onyo/mock-pp-conciliacao-bff';

const { PP_BASE_URL, PP_BASE_URL_AUTH, PP_GEOCODE_KEY } = process.env;

export const ENDPOINTS = {
  payments: {
    get: (paymentsPerPage: number, page: number) =>
      `${BASE_URL_MOCK}/payments?size=${paymentsPerPage}&page=${page}`,
    history: {
      get: (batch: number | string) =>
        `${BASE_URL_MOCK}/payments/history/${batch}`,
    },
    erros: {
      get: (rejectedLinesPerPage: number, page: number) =>
        `${BASE_URL_NEW_MOCK}/erros?_limit=${rejectedLinesPerPage}&_page=${page}`,
    },
  },
  user: {
    list: `${PP_BASE_URL_AUTH}/user`,
  },
  login: {
    post: `${PP_BASE_URL_AUTH}/auth/login/user`,
  },
  auth: {
    check: {
      get: `${PP_BASE_URL_AUTH}/auth/check`,
    },
    refreshToken: {
      post: `${PP_BASE_URL_AUTH}/auth/refresh-token`,
    },
  },
  cep: {
    get: (cep: string) =>
      `https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=${PP_GEOCODE_KEY}`,
  },
  wallet: {
    get: (merchantId: string) => `${PP_BASE_URL}/merchant/wallet/${merchantId}`,
    post: `${PP_BASE_URL}/merchant/wallet`,
  },
  bankAccount: {
    get: (merchantId: string) => `${PP_BASE_URL}/merchant/bank/${merchantId}`,
    post: (merchantId: string) => `${PP_BASE_URL}/merchant/bank/${merchantId}`,
  },
  establishment: {
    get: (paymentsPerPage: number, page: number) =>
      `${PP_BASE_URL}/merchant/wallet?size=${paymentsPerPage}&page=${page}`,
    search: (paymentsPerPage: number, page: number) =>
      `${PP_BASE_URL}/merchant/wallet/filter?size=${paymentsPerPage}&page=${page}`,
    active: (merchantId: string) =>
      `${PP_BASE_URL}/merchant/wallet/active/${merchantId}`,
    synchronize: (merchantId: string) =>
      `${PP_BASE_URL}/merchant/wallet/sync/${merchantId}`,
    credentials: {
      list: (merchantId: string) =>
        `${PP_BASE_URL}/merchant/wallet/${merchantId}/oauth2`,
    },
  },
  establishmentMktp: {
    get: (paymentsPerPage: number, page: number) =>
      `${PP_BASE_URL}/merchant/contract/all?size=${paymentsPerPage}&page=${page}`,
    search: (paymentsPerPage: number, page: number, filter?: any) =>
      `${PP_BASE_URL}/merchant/profile/all?size=${paymentsPerPage}&page=${page}${
        filter?.id ? `&companyId=${filter?.id}` : ''
      }${filter?.document ? `&document=${filter?.document}` : ''}${
        filter?.socialReason ? `&name=${filter?.socialReason}` : ''
      }`,
  },
  establishmentMktpDetails: {
    get: (id: string) => `${PP_BASE_URL}/merchant/contract/${id}`,
  },
  paymentsMethods: {
    get: (merchantId: string) =>
      `${PP_BASE_URL}/payments/methods/internal/MARKETPLACE?merchantId=${merchantId}`,
    post: (merchantId: string, isOffline?: boolean) =>
      `${PP_BASE_URL}/payments/methods/${
        isOffline ? 'offline' : 'online'
      }/${merchantId}`,
    put: (merchantId: string, isOffline?: boolean) =>
      `${PP_BASE_URL}/payments/methods/${
        isOffline ? 'offline' : 'online'
      }/${merchantId}`,
  },
  paymentOptions: {
    fees: {
      get: (merchantId: string) =>
        `${PP_BASE_URL}/payments/methods/internal/WALLET?merchantId=${merchantId}`,
      post: `${PP_BASE_URL}/payments/methods/WALLET`,
      put: (id: string) => `${PP_BASE_URL}/payments/methods/${id}`,
    },
  },
  brand: {
    create: `${PP_BASE_URL}/merchant/brand`,
    list: (brandsPerPage: number, page: number) =>
      `${PP_BASE_URL}/merchant/brand?size=${brandsPerPage}&page=${page}`,
    listAll: `${PP_BASE_URL}/merchant/brand/all`,
  },
  offlinePayment: {
    getEnabledAllOfflinePayment: `${PP_BASE_URL}/merchant/internal/permitted-offline-payment/all-companies-enabled`,
    setEnabledAllOfflinePayment: `${PP_BASE_URL}/merchant/internal/permitted-offline-payment/all-companies-enabled`,
    list: (perPage: number, page: number) =>
      `${PP_BASE_URL}/merchant/internal/permitted-offline-payment?size=${perPage}&page=${page}`,
    add: `${PP_BASE_URL}/merchant/internal/permitted-offline-payment`,
    addCsv: `${PP_BASE_URL}/merchant/internal/permitted-offline-payment/import-csv`,
    remove: (id: string) =>
      `${PP_BASE_URL}/merchant/internal/permitted-offline-payment/${id}`,
    getById: (id: string) =>
      `${PP_BASE_URL}/merchant/internal/permitted-offline-payment/${id}`,
  },
  logistics: {
    searchEcs: (name: string) =>
      `${PP_BASE_URL}/merchant/internal/logistic/by-merchant?nameContains=&nameStartsWith=${name}`,
    getEcs: `${PP_BASE_URL}/merchant/internal/logistic/by-merchant`,
    getMaxRadius: (merchantId: string) =>
      `${PP_BASE_URL}/logistics/by-merchant/${merchantId}`,
    saveValuesPerKm: `${PP_BASE_URL}/logistics/by-merchant`,
    deliveryMonitoring: {
      status: {
        getAll: `${PP_BASE_URL}/logistics/status`,
        getPending: `${PP_BASE_URL}/logistics/status/pending`,
        getAccepted: `${PP_BASE_URL}/logistics/status/accepted`,
        getWaiting: `${PP_BASE_URL}/logistics/status/waiting`,
        getProgress: `${PP_BASE_URL}/logistics/status/progress`,
        getDelivered: `${PP_BASE_URL}/logistics/status/delivered`,
        getCancelled: `${PP_BASE_URL}/logistics/status/cancelled`,
        getManual: `${PP_BASE_URL}/logistics/status/manual`,
        getLastOrders: `${PP_BASE_URL}/logistics/status/lastOrdersDay`,
        getOrderByDisplayId: (orderDisplayId: string) =>
          `${PP_BASE_URL}/logistics/status/id/${orderDisplayId}`,
      },
    },
  },
  transactions: {
    getAll: (page: number, size: number) =>
      `${PP_BASE_URL}/transactions?page=${page}&size=${size}`,
    cancel: (transactionId: string) =>
      `${PP_BASE_URL}/transactions/cancelling/${transactionId}`,
  },
  consumers: {
    get: (page: number, size: number) =>
      `${PP_BASE_URL}/customer/profile/all?page=${page}&size=${size}`,
    search: (page: number, size: number, filter: any) =>
      `${PP_BASE_URL}/customer/profile/all?page=${page}&size=${size}${
        filter?.id ? `&customerId=${filter?.id}` : ''
      }${filter?.name ? `&name=${filter?.name}` : ''}${
        filter?.email ? `&email=${filter?.email}` : ''
      }${filter?.document ? `&cpf=${filter?.document}` : ''}
      ${filter?.phoneNumber ? `&phone=${filter?.phoneNumber}` : ''}
      ${
        filter?.customerSince ? `&customerSince=${filter?.customerSince}` : ''
      }`,
  },
  operations: {
    search: (value: string) =>
      `${PP_BASE_URL}/merchant/internal/register/find-by-name-cnpj?data=${value}`,
    post: `${PP_BASE_URL}/credential/user/migrate`,
    loadUsers: (cnpj: string) =>
      `${PP_BASE_URL}/credential/user/find?cnpj=${cnpj}`,
    resendPassword: () => `${PP_BASE_URL}/credential/user/reset-password`,
    inactivateUser: (userId: string) =>
      `${PP_BASE_URL}/credential/user/${userId}/inactivate`,
  },
  paymentsConfig: {
    list: `${PP_BASE_URL}/payment/account/master`,
    brand: `${PP_BASE_URL}/payment/account/brand`,
    update: (id: any) => `${PP_BASE_URL}/payment/account/master/${id}`,
  },
};
