import * as React from 'react';

function ArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.88712 3.52667L2.06045 0.700006C1.99848 0.637521 1.92474 0.587925 1.8435 0.554079C1.76226 0.520233 1.67512 0.502808 1.58712 0.502808C1.49911 0.502808 1.41197 0.520233 1.33073 0.554079C1.24949 0.587925 1.17576 0.637521 1.11378 0.700006C0.989616 0.824915 0.919922 0.993883 0.919922 1.17001C0.919922 1.34613 0.989616 1.5151 1.11378 1.64001L3.47378 4.00001L1.11378 6.36001C0.989616 6.48491 0.919922 6.65388 0.919922 6.83001C0.919922 7.00613 0.989616 7.1751 1.11378 7.30001C1.17608 7.36179 1.24995 7.41068 1.33118 7.44385C1.4124 7.47703 1.49938 7.49385 1.58712 7.49334C1.67485 7.49385 1.76183 7.47703 1.84305 7.44385C1.92428 7.41068 1.99816 7.36179 2.06045 7.30001L4.88712 4.47334C4.9496 4.41136 4.9992 4.33763 5.03304 4.25639C5.06689 4.17515 5.08432 4.08801 5.08432 4.00001C5.08432 3.912 5.06689 3.82486 5.03304 3.74362C4.9992 3.66238 4.9496 3.58865 4.88712 3.52667Z"
        fill="#9B96A0"
      />
    </svg>
  );
}

export default ArrowDownIcon;
