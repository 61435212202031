import styled from 'styled-components';
import { INavItem } from './nested-paths.types';

export const NavList = styled.ul`
  list-style: none;
`;

export const NavItemSubPaths = styled.li<INavItem>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 6px;
  border-right: ${(props) =>
    props.isActive ? '2px solid #6A2CB1' : 'transparent'};
  color: ${(props) => (props.isActive ? '#6A2CB1' : '#4B415C')};

  svg {
    path {
      fill: ${(props) => (props.isActive ? '#6A2CB1' : '#4B415C')};
    }
  }
`;

export const NavItem = styled.li``;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;
