import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex !important',
      width: 520,
      padding: 32,
      flexDirection: 'column',
      alignItems: 'flex-start !important',
      gap: 24,
      borderRadius: 8,
      backgroundColor: '#FFF !important',

      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 10,
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
    },
    title: {
      color: '#28262C !important',
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '130%',
      letterSpacing: -0.16,
    },
    content: {
      color: '#49454F',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '140%',
    },
    footer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: 8,
    },
    btnPrimary: {
      width: '100%',
      display: 'flex !important',
      padding: '14.5px 16px !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      gap: 16,
      alignSelf: 'stretch !important',
      borderRadius: 4,
      backgroundColor: '#DC2626 !important',
      color: '#FFF !important',
      marginBottom: 8,
    },
    btnSecondary: {
      width: '100%',
      display: 'flex !important',
      padding: '14.5px 16px !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      gap: 16,
      alignSelf: 'stretch !important',
      borderRadius: 4,
      backgroundColor: 'transparent !important',
      border: '1px solid #DC2626 !important',
      color: '#DC2626 !important',
    },
  }),
);
