import * as React from 'react';

function ClockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 2.66659C5.05464 2.66659 2.66683 5.0544 2.66683 7.99992C2.66683 10.9454 5.05464 13.3333 8.00016 13.3333C10.9457 13.3333 13.3335 10.9454 13.3335 7.99992C13.3335 5.0544 10.9457 2.66659 8.00016 2.66659ZM1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992Z"
        fill="#908A99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 4C8.36835 4 8.66683 4.29848 8.66683 4.66667V7.72386L10.4716 9.5286C10.7319 9.78895 10.7319 10.2111 10.4716 10.4714C10.2112 10.7318 9.78911 10.7318 9.52876 10.4714L7.52876 8.4714C7.40373 8.34638 7.3335 8.17681 7.3335 8V4.66667C7.3335 4.29848 7.63197 4 8.00016 4Z"
        fill="#908A99"
      />
    </svg>
  );
}

export default ClockIcon;
