// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../node_modules/react-notifications-component/dist/theme.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../node_modules/antd/dist/antd.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../node_modules/tippy.js/dist/tippy.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  border: 0;\n  outline: none;\n}\n\nbody {\n  height: 100vh;\n  font-family: 'Open Sans', serif;\n  background: #fbf9fc;\n}\n\na {\n  text-decoration: none;\n}\n", "",{"version":3,"sources":["webpack://./src/assets/css/global.css"],"names":[],"mappings":"AAKA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');\n@import 'react-notifications-component/dist/theme.css';\n@import 'antd/dist/antd.css';\n@import 'tippy.js/dist/tippy.css';\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  border: 0;\n  outline: none;\n}\n\nbody {\n  height: 100vh;\n  font-family: 'Open Sans', serif;\n  background: #fbf9fc;\n}\n\na {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
