import './global.css';
import './assets/css/index.css';
import { ReactNotifications } from 'react-notifications-component';

import { Suspense, useEffect, useCallback } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoutes from './routes/routes';
import Loading from './components/Loading/loading.component';
import Login from './pages/Login/login.component';
import 'devextreme/data/odata/store';
import 'devextreme/dist/css/dx.light.css';
import { ENDPOINTS } from './endpoints';
import {
  getAuthFromLocalStorage,
  getAuthFromSessionStorage,
  setAuthInLocalStorage,
  setAuthInSessionStorage,
} from './util/storage';
import { UserProvider } from './hooks/user';
import api from './config/api';
import { DeliveryAreaPolygonProvider } from './pages/Logistics/DeliveryAreaPolygon/delivery-area-polygon.hook';

export default function Root() {
  const getRefreshToken = useCallback(async () => {
    const token =
      getAuthFromLocalStorage() || getAuthFromSessionStorage() || '';
    const { data } = await api.post(ENDPOINTS.auth.refreshToken.post, {
      headers: { Authorization: token },
    });
    const { status } = data;

    if (status === 200) {
      const { token, tokenType } = await data;
      const hasTokenStoredInLocalStorage = getAuthFromLocalStorage();
      const hasTokenStoredInSessionStorage = getAuthFromSessionStorage();
      if (hasTokenStoredInLocalStorage) {
        setAuthInLocalStorage(`${tokenType} ${token}`);
      } else if (hasTokenStoredInSessionStorage) {
        setAuthInSessionStorage(`${tokenType} ${token}`);
      }
    }
  }, []);

  const checkAuth = useCallback(async () => {
    const token =
      getAuthFromLocalStorage() || getAuthFromSessionStorage() || '';

    const { status } = await api.get(ENDPOINTS.auth.check.get, {
      headers: { Authorization: token },
    });
    if (status === 401) {
      getRefreshToken();
    }
  }, [getRefreshToken]);

  useEffect(() => {
    const entries = window.performance.getEntriesByType('navigation');
    const navType = entries.map((nav: any) => nav.type)?.[0];
    if (navType === 'reload') {
      checkAuth();
    }
  }, [checkAuth]);

  return (
    <BrowserRouter>
      <UserProvider>
        <DeliveryAreaPolygonProvider>
          <ReactNotifications />
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/" component={Login} />
              <PrivateRoutes />
            </Switch>
          </Suspense>
        </DeliveryAreaPolygonProvider>
      </UserProvider>
    </BrowserRouter>
  );
}
