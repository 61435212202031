import * as React from 'react';

function MopedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.6668C11.6318 10.6668 11.3333 10.9653 11.3333 11.3335C11.3333 11.7017 11.6318 12.0002 12 12.0002C12.3682 12.0002 12.6667 11.7017 12.6667 11.3335C12.6667 10.9653 12.3682 10.6668 12 10.6668ZM10 11.3335C10 10.2289 10.8954 9.3335 12 9.3335C13.1046 9.3335 14 10.2289 14 11.3335C14 12.4381 13.1046 13.3335 12 13.3335C10.8954 13.3335 10 12.4381 10 11.3335Z"
        fill="#908A99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3335 3.33341C9.3335 2.96522 9.63197 2.66675 10.0002 2.66675H10.6668C11.7714 2.66675 12.6668 3.56218 12.6668 4.66675V8.00008C12.6668 8.33946 12.4119 8.62468 12.0746 8.66258C10.7978 8.80605 9.71708 9.67061 9.29683 10.8848C9.20389 11.1533 8.95097 11.3334 8.66683 11.3334H6.66683C6.66683 12.438 5.7714 13.3334 4.66683 13.3334C3.56226 13.3334 2.66683 12.438 2.66683 11.3334H2.00016C1.63197 11.3334 1.3335 11.0349 1.3335 10.6667V10.0001C1.3335 8.52732 2.5274 7.33341 4.00016 7.33341H6.00016C6.36835 7.33341 6.66683 7.63189 6.66683 8.00008V10.0001H8.21858C8.81653 8.71913 9.96455 7.77901 11.3335 7.44303V4.66675C11.3335 4.29856 11.035 4.00008 10.6668 4.00008H10.0002C9.63197 4.00008 9.3335 3.7016 9.3335 3.33341ZM5.3335 10.0001V8.66675H4.00016C3.26378 8.66675 2.66683 9.2637 2.66683 10.0001H5.3335ZM4.00016 11.3334C4.00016 11.7016 4.29864 12.0001 4.66683 12.0001C5.03502 12.0001 5.3335 11.7016 5.3335 11.3334H4.00016Z"
        fill="#908A99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3335 6.00016C3.3335 5.63197 3.63197 5.3335 4.00016 5.3335H6.00016C6.36835 5.3335 6.66683 5.63197 6.66683 6.00016C6.66683 6.36835 6.36835 6.66683 6.00016 6.66683H4.00016C3.63197 6.66683 3.3335 6.36835 3.3335 6.00016Z"
        fill="#908A99"
      />
    </svg>
  );
}

export default MopedIcon;
