export const getCNPJMask = (cnpj: string) => {
  if (!cnpj) return '';

  const maskedCNPJ = cnpj
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{2})/, '$1-$2');

  return maskedCNPJ;
};

export const removeCNPJMask = (cnpj: string) => {
  if (!cnpj) return '';

  const cnpjWithMask = cnpj
    .replaceAll('.', '')
    .replace('-', '')
    .replace('/', '');

  return cnpjWithMask;
};
