import * as React from 'react';

function PaymentsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 3.33333C1.33325 2.59695 1.93021 2 2.66659 2H13.3333C14.0696 2 14.6666 2.59695 14.6666 3.33333V10C14.6666 10.7364 14.0696 11.3333 13.3333 11.3333H2.66659C1.93021 11.3333 1.33325 10.7364 1.33325 10V3.33333ZM13.3333 3.33333L2.66659 3.33333V10H13.3333V3.33333Z"
        fill="#EBE6F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13.3333C4 12.9651 4.29848 12.6666 4.66667 12.6666H11.3333C11.7015 12.6666 12 12.9651 12 13.3333C12 13.7015 11.7015 14 11.3333 14H4.66667C4.29848 14 4 13.7015 4 13.3333Z"
        fill="#EBE6F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99992 10C6.36811 10 6.66658 10.2985 6.66658 10.6667V13.3333C6.66658 13.7015 6.36811 14 5.99992 14C5.63173 14 5.33325 13.7015 5.33325 13.3333V10.6667C5.33325 10.2985 5.63173 10 5.99992 10Z"
        fill="#EBE6F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 10C10.3681 10 10.6666 10.2985 10.6666 10.6667V13.3333C10.6666 13.7015 10.3681 14 9.99992 14C9.63173 14 9.33325 13.7015 9.33325 13.3333V10.6667C9.33325 10.2985 9.63173 10 9.99992 10Z"
        fill="#EBE6F2"
      />
    </svg>
  );
}

export default PaymentsIcon;
