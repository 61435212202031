import { useFormContext } from 'react-hook-form';
import { IProps } from './checkbox-field-react-hook-form.types';
import { Content } from './checkbox-field-react-hook-form.styles';
import Typography from '../Typography/typography.component';

function Checkbox({ name, label, id }: IProps) {
  const { register } = useFormContext();
  return (
    <Content>
      <input id={id} type="checkbox" {...register(name)} />
      <Typography as="label" htmlFor={id} color="#61596C">
        {label}
      </Typography>
    </Content>
  );
}

export default Checkbox;
