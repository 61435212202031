import styled from 'styled-components';
import { INavItem } from './sidebar.types';

export const Menu = styled.aside`
  background: #fff;
  min-width: 256px;
  height: calc(100vh - 72px);
`;

export const NavBar = styled.nav``;

export const NavList = styled.ul`
  margin-top: 24px;
  margin-left: 16px;
`;

export const NavItem = styled.li<INavItem>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.0115em;
  width: 100%;
  height: 32px;
  border-right: ${(props) =>
    props.isActive ? '2px solid #6A2CB1' : 'transparent'};

  a {
    color: ${(props) => (props.isActive ? '#6A2CB1' : '#4B415C')};
    margin-left: 10px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0115em;
  }

  svg {
    path {
      fill: ${(props) => (props.isActive ? '#6A2CB1' : '#4B415C')};
    }
  }
`;
