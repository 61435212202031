import * as React from 'react';

function AlertClicleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 2.66659C5.0544 2.66659 2.66659 5.0544 2.66659 7.99992C2.66659 10.9454 5.0544 13.3333 7.99992 13.3333C10.9454 13.3333 13.3333 10.9454 13.3333 7.99992C13.3333 5.0544 10.9454 2.66659 7.99992 2.66659ZM1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992Z"
        fill={props.fill || '#35007D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 4.66675C8.36811 4.66675 8.66659 4.96522 8.66659 5.33341V8.00008C8.66659 8.36827 8.36811 8.66675 7.99992 8.66675C7.63173 8.66675 7.33325 8.36827 7.33325 8.00008V5.33341C7.33325 4.96522 7.63173 4.66675 7.99992 4.66675Z"
        fill={props.fill || '#35007D'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33325 10.6667C7.33325 10.2985 7.63173 10 7.99992 10H8.00659C8.37477 10 8.67325 10.2985 8.67325 10.6667C8.67325 11.0349 8.37477 11.3333 8.00659 11.3333H7.99992C7.63173 11.3333 7.33325 11.0349 7.33325 10.6667Z"
        fill={props.fill || '#35007D'}
      />
    </svg>
  );
}

export default AlertClicleIcon;
