import * as React from 'react';

function ExternalLinkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4C0 2.89543 0.895431 2 2 2H5.33333C5.70152 2 6 2.29848 6 2.66667C6 3.03486 5.70152 3.33333 5.33333 3.33333H2C1.63181 3.33333 1.33333 3.63181 1.33333 4V10C1.33333 10.3682 1.63181 10.6667 2 10.6667H8C8.36819 10.6667 8.66667 10.3682 8.66667 10V6.66667C8.66667 6.29848 8.96514 6 9.33333 6C9.70152 6 10 6.29848 10 6.66667V10C10 11.1046 9.10457 12 8 12H2C0.895431 12 0 11.1046 0 10V4Z"
        fill="#35007D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8047 0.195262C12.0651 0.455612 12.0651 0.877722 11.8047 1.13807L5.13807 7.80474C4.87772 8.06509 4.45561 8.06509 4.19526 7.80474C3.93491 7.54439 3.93491 7.12228 4.19526 6.86193L10.8619 0.195262C11.1223 -0.0650874 11.5444 -0.0650874 11.8047 0.195262Z"
        fill="#35007D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3335 0.666667C7.3335 0.298477 7.63197 0 8.00016 0H11.3335C11.7017 0 12.0002 0.298477 12.0002 0.666667V4C12.0002 4.36819 11.7017 4.66667 11.3335 4.66667C10.9653 4.66667 10.6668 4.36819 10.6668 4V1.33333H8.00016C7.63197 1.33333 7.3335 1.03486 7.3335 0.666667Z"
        fill="#35007D"
      />
    </svg>
  );
}

export default ExternalLinkIcon;
