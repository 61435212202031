import styled from 'styled-components';
import { IBreadcumbItem } from './breadcumb.types';

export const Content = styled.div`
  margin-top: 8px;

  svg {
    margin: 0 6px 0 6px;
  }
`;

export const BreadcumbItem = styled.span<IBreadcumbItem>`
  font-size: 10px;
  line-height: 16px;
  font-weight: ${(props) => (props.isLastItem ? '600' : '400')};
  letter-spacing: 1.5%;
  color: ${(props) => (props.isLastItem ? '#6A2CB1' : '#9B96A0')};
`;
