import * as React from 'react';

function EditIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.1105 2.52688H10.7493C10.5265 2.52688 10.3127 2.6156 10.1551 2.77351C9.99755 2.93143 9.90901 3.14562 9.90901 3.36895C9.90901 3.59228 9.99755 3.80646 10.1551 3.96438C10.3127 4.1223 10.5265 4.21102 10.7493 4.21102H14.1105C14.3334 4.21102 14.5471 4.29974 14.7047 4.45765C14.8623 4.61557 14.9508 4.82976 14.9508 5.05309V13.238L13.6315 12.017C13.4766 11.8716 13.2724 11.7904 13.0601 11.7896H5.70751C5.48465 11.7896 5.27091 11.7009 5.11333 11.543C4.95574 11.3851 4.86721 11.1709 4.86721 10.9476V10.1055C4.86721 9.88218 4.77868 9.66799 4.62109 9.51008C4.4635 9.35216 4.24977 9.26344 4.02691 9.26344C3.80405 9.26344 3.59031 9.35216 3.43273 9.51008C3.27514 9.66799 3.18661 9.88218 3.18661 10.1055V10.9476C3.18661 11.6176 3.4522 12.2601 3.92496 12.7339C4.39772 13.2076 5.03892 13.4738 5.70751 13.4738H12.7324L15.2533 15.7726C15.4083 15.918 15.6124 15.9993 15.8247 16C15.94 15.9987 16.054 15.9759 16.1609 15.9326C16.3112 15.8669 16.439 15.7585 16.5286 15.6209C16.6182 15.4832 16.6656 15.3223 16.665 15.1579V5.05309C16.6651 4.71849 16.5988 4.38722 16.47 4.07851C16.3412 3.7698 16.1525 3.4898 15.9148 3.25477C15.6772 3.01975 15.3953 2.83437 15.0856 2.70942C14.7758 2.58446 14.4444 2.52242 14.1105 2.52688V2.52688ZM6.19488 8.42137H8.22841C8.45127 8.42137 8.66501 8.33265 8.82259 8.17473C8.98018 8.01681 9.06871 7.80263 9.06871 7.5793V5.54149C9.06935 5.43067 9.04816 5.32081 9.00634 5.21821C8.96452 5.11562 8.90291 5.0223 8.82503 4.94362L4.13615 0.244868C3.97871 0.0880315 3.76573 0 3.54374 0C3.32174 0 3.10876 0.0880315 2.95132 0.244868L0.909392 2.2911C0.752886 2.44887 0.665039 2.66229 0.665039 2.88476C0.665039 3.10722 0.752886 3.32064 0.909392 3.47842L5.59827 8.17717C5.67679 8.25521 5.76991 8.31696 5.87229 8.35886C5.97467 8.40077 6.0843 8.42201 6.19488 8.42137V8.42137ZM3.53953 2.03006L7.38811 5.88674V6.72881H6.54781L2.69083 2.88055L3.53953 2.03006Z"
        fill="#35007D"
      />
    </svg>
  );
}

export default EditIcon;
