import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { MicrosoftIcon } from '../../../../assets/icons';
import { displayNotification } from '../../../../util/notification';
import { firebaseConfig } from './firebase-config';

const provider = new OAuthProvider('microsoft.com');
initializeApp(firebaseConfig);

provider.setCustomParameters({
  tenant: '7e6b693c-2b0c-4949-955f-aced9adbc8d6',
});

function FirebaseAuth() {
  const auth = getAuth();
  const history = useHistory();

  const signInWithMicrosoft = async () => {
    await signInWithPopup(auth, provider)
      .then((result) => {
        if (result != null) {
          OAuthProvider.credentialFromResult(result);
          history.push({
            pathname: '/estabelecimento/listagem-wallet',
          });
        }
      })
      .catch((e) => {
        displayNotification({
          message: 'Ocorreu um erro ao tentar realizar login!',
          type: 'danger',
        });
      });
  };

  return (
    <button
      style={{ cursor: 'pointer', padding: 6, marginTop: 40 }}
      type="button"
      onClick={() => signInWithMicrosoft()}
    >
      <span>
        <MicrosoftIcon /> Sign in with Microsoft
      </span>
    </button>
  );
}

export default FirebaseAuth;
