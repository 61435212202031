/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { convertDistance, getAreaOfPolygon } from 'geolib';
import { displayNotification } from '@/util';
import api from '../../../config/api';
import { ENDPOINTS } from '../../../endpoints';
import { getAddressObject, getAddressString } from './utils/get-address';

interface DeliveryAreaPolygonContextData {
  step: any;
  setStep: any;
  address: any;
  setAddress: any;
  merchantInfo: any;
  setMerchantInfo: any;
  onSave: any;
  search: string;
  setSearch: any;
  isLoading: boolean;
  setIsLoading: any;
  fetchMerchantInfo: any;
  modalIsOpen: boolean;
  setModalIsOpen: any;
  hasPolygon: boolean;
  setPolygonEditOpen: any;
  polygonEditOpen: any;
  polygonHasSaved: boolean;
  setPolygonHasSaved: any;
}

export const DeliveryAreaPolygonContext =
  createContext<DeliveryAreaPolygonContextData>({
    step: 2,
    setStep: () => {},
    address: {},
    setAddress: () => {},
    merchantInfo: () => {},
    setMerchantInfo: () => {},
    onSave: () => {},
    search: '',
    setSearch: () => {},
    isLoading: false,
    setIsLoading: () => {},
    setModalIsOpen: () => {},
    modalIsOpen: false,
    polygonHasSaved: false,
  } as DeliveryAreaPolygonContextData);

type Props = {
  children: React.ReactNode;
};

export function DeliveryAreaPolygonProvider({ children }: Props) {
  const [step, setStep] = useState(1);
  const [search, setSearch] = useState<string>('');
  const [address, setAddress] = useState<any | null>({});
  const [isLoading, setIsLoading] = useState(false);
  const [merchantInfo, setMerchantInfo] = useState<any>({});
  const [merchantId, setMerchantId] = useState<string>('');
  const [merchantName, setMerchantName] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasPolygon, setHasPolygon] = useState(false);
  const [polygonEditOpen, setPolygonEditOpen] = useState(false);
  const [polygonHasSaved, setPolygonHasSaved] = useState(false);

  const getAddress = () => {
    if (address?.address_components && address?.address_components.length > 0) {
      return { ...getAddressObject(address?.address_components || []) };
    }
    return { ...merchantInfo.address };
  };

  const onSave = async () => {
    try {
      const areaReference = getAreaOfPolygon(
        merchantInfo?.polygon?.[0]?.geoCoordinates,
      );
      const maxRadius =
        Math.ceil(convertDistance(areaReference, 'km') / 1000) * 1000;
      if (merchantInfo?.polygon?.[0]?.geoCoordinates?.length === 0) {
        merchantInfo.polygon = [];
      }
      const data = {
        ...merchantInfo,
        maxRadius: maxRadius || 0,
        name: address?.name || merchantInfo.name,
        address: {
          ...getAddress(),
          latitude: address?.center?.lat,
          longitude: address?.center?.lng,
        },
      };
      await api.post(ENDPOINTS.logistics.saveValuesPerKm, data);
      setMerchantInfo(data);

      const toastMessage = maxRadius
        ? 'Salvo com sucesso.'
        : 'Salvo com sucesso. Polígono removido com sucesso!';
      displayNotification({
        message: toastMessage,
        type: 'success',
      });
      setStep(1);
      setPolygonHasSaved(true);
      setModalIsOpen(false);
    } catch (e) {
      console.error(e);
      displayNotification({
        message: 'Erro.',
        type: 'danger',
      });
    }
  };

  const fetchMerchantInfo = async (selectedMerchantId: string) => {
    setMerchantId(selectedMerchantId);
    const { data } = await api.get(
      ENDPOINTS.logistics.getMaxRadius(selectedMerchantId),
    );
    setMerchantInfo({
      ...data,
      name: data?.address?.name || data?.name,
    });

    if (data?.polygon?.[0]?.geoCoordinates?.length > 0) {
      setHasPolygon(true);
    }
    if (data?.address) {
      let name = '';

      if (merchantName) {
        name = merchantName;
      }

      if (data?.name) {
        name = data?.name;
      }

      setSearch(
        `${name ? `${name} - ` : ''}${getAddressString(data?.address)}`,
      );

      setAddress({
        name,
        formatted_address: getAddressString(data?.address),
        address_components: [],
        center: {
          lat: data?.address.latitude,
          lng: data?.address.longitude,
        },
      });
    }
  };

  return (
    <DeliveryAreaPolygonContext.Provider
      value={{
        step,
        setStep,
        address,
        setAddress,
        merchantInfo,
        setMerchantInfo,
        onSave,
        search,
        setSearch,
        isLoading,
        setIsLoading,
        fetchMerchantInfo,
        setModalIsOpen,
        modalIsOpen,
        hasPolygon,
        setPolygonEditOpen,
        polygonEditOpen,
        polygonHasSaved,
        setPolygonHasSaved,
      }}
    >
      {children}
    </DeliveryAreaPolygonContext.Provider>
  );
}

export function useDeliveryAreaPolygon() {
  const context = useContext(DeliveryAreaPolygonContext);
  return context;
}
