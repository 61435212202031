import * as React from 'react';

function CircleCheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 2.66671C5.0544 2.66671 2.66659 5.05452 2.66659 8.00004C2.66659 10.9456 5.0544 13.3334 7.99992 13.3334C10.9454 13.3334 13.3333 10.9456 13.3333 8.00004C13.3333 5.05452 10.9454 2.66671 7.99992 2.66671ZM1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C11.6818 1.33337 14.6666 4.31814 14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004Z"
        fill="#15803D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4713 6.19526C10.7317 6.45561 10.7317 6.87772 10.4713 7.13807L7.80466 9.80474C7.54431 10.0651 7.1222 10.0651 6.86185 9.80474L5.52851 8.4714C5.26816 8.21106 5.26816 7.78895 5.52851 7.5286C5.78886 7.26825 6.21097 7.26825 6.47132 7.5286L7.33325 8.39052L9.52851 6.19526C9.78886 5.93491 10.211 5.93491 10.4713 6.19526Z"
        fill="#15803D"
      />
    </svg>
  );
}

export default CircleCheckIcon;
