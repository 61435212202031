import * as React from 'react';

function Establishment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 21H21"
        stroke="#2D2931"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 7C4 6.44772 3.55228 6 3 6C2.44772 6 2 6.44772 2 7H4ZM10 7C10 6.44772 9.55228 6 9 6C8.44772 6 8 6.44772 8 7H10ZM10 8C10 7.44772 9.55228 7 9 7C8.44772 7 8 7.44772 8 8H10ZM16 7C16 6.44772 15.5523 6 15 6C14.4477 6 14 6.44772 14 7H16ZM16 8C16 7.44772 15.5523 7 15 7C14.4477 7 14 7.44772 14 8H16ZM21 7H22C22 6.44772 21.5523 6 21 6V7ZM3 7L2.10557 6.55279C1.95058 6.86277 1.96714 7.23091 2.14935 7.52573C2.33156 7.82055 2.65342 8 3 8V7ZM5 3V2C4.62123 2 4.27496 2.214 4.10557 2.55279L5 3ZM19 3L19.8944 2.55279C19.725 2.214 19.3788 2 19 2V3ZM20.1056 7.44721C20.3526 7.94119 20.9532 8.14142 21.4472 7.89443C21.9412 7.64744 22.1414 7.04676 21.8944 6.55279L20.1056 7.44721ZM2 7V8H4V7H2ZM2 8C2 10.2091 3.79086 12 6 12V10C4.89543 10 4 9.10457 4 8H2ZM6 12C8.20914 12 10 10.2091 10 8H8C8 9.10457 7.10457 10 6 10V12ZM10 8V7H8V8H10ZM8 8C8 10.2091 9.79086 12 12 12V10C10.8954 10 10 9.10457 10 8H8ZM12 12C14.2091 12 16 10.2091 16 8H14C14 9.10457 13.1046 10 12 10V12ZM16 8V7H14V8H16ZM14 8C14 10.2091 15.7909 12 18 12V10C16.8954 10 16 9.10457 16 8H14ZM18 12C20.2091 12 22 10.2091 22 8H20C20 9.10457 19.1046 10 18 10V12ZM22 8V7H20V8H22ZM21 6H3V8H21V6ZM3.89443 7.44721L5.89443 3.44721L4.10557 2.55279L2.10557 6.55279L3.89443 7.44721ZM5 4H19V2H5V4ZM18.1056 3.44721L20.1056 7.44721L21.8944 6.55279L19.8944 2.55279L18.1056 3.44721Z"
        fill="#2D2931"
      />
      <path
        d="M5 21V10.85"
        stroke="#2D2931"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 21V10.85"
        stroke="#2D2931"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 21V17C9 15.8954 9.89543 15 11 15H13C14.1046 15 15 15.8954 15 17V21"
        stroke="#2D2931"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Establishment;
