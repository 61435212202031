import * as React from 'react';

function ArrowSortedUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="6"
      height="5"
      viewBox="0 0 6 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 0L5.59808 4.5L0.401924 4.5L3 0Z" fill="#454545" />
    </svg>
  );
}

export default ArrowSortedUpIcon;
